import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBlog } from "../../redux/Blog/BlogSlice";
import { Link } from "react-router-dom";
import { useState } from "react";


export default function Blog()  
{
    const {loading, error, blog } = useSelector(state => state.blog)

    const [searchTerm, setsearchTerm ] = useState("")
    
    const dispatch = useDispatch()
    
    useEffect( () => {
        dispatch(getBlog())
    }, [dispatch])


    const handleSearch = (e) => {
        setsearchTerm(e.target.value)

    }

    const filteredData = blog.filter(el => el.title.toLowerCase().includes(searchTerm.toLowerCase()))

    return (
        <>
            {
                loading && 
                <div className="loading">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            }
        <div className="blog">
                <div class="container text-center">
                    <div class="row">
                    <div className="col mt-2">
                        <Link style={{ textDecoration:"none" }} to={"/"} ><h3 style={{ fontSize: "20px", marginLeft: "20px", color: "#000" }}>Home</h3></Link>
                    </div>
                        <div class="col mt-2">
                        
                        </div>
                        <div class="col mt-2">
                            <form class="d-flex flex-row" role="search">
                            <input class="form-control me-2" type="search" onChange={e=> handleSearch(e)} placeholder="Search" aria-label="Search" />
                            <a type="submit"><i style={{ color:"#3E251E"}} class="bi bi-search fs-5"></i></a>
                            </form>
                        </div>
                    </div>
                    <div class="row">
                    <div className="col mt-2">
                    </div>
                        <div class="col mt-2">
                        {/* <select class="form-select bg-transparent" aria-label="Default select example">
                            <option selected>select Category</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select> */}
                        </div>
                        <div class="col mt-2">

                        </div>
                    </div>
                </div>

           
        
        <div className="d-flex f-3 flex-wrap">

            {
                filteredData.map(post => {
                    return(
                            <div className="card-blog mt-3 m-5" style={{width: "18rem"}}>
                                <img className="rounded" src={`https://aminedaaboub.com/storage/${post.photo}`} alt={post.title} />
                                    <div className="card-body">
                                    <h5 className="card-title card-title-blog">{post.title}</h5>
                                    <p className="card-text card-text-blog">{post.description}</p>
                                    </div>
                            </div>
                    )
                })
            }
        </div>
        </div>
            {
                (filteredData.length == 0 && !loading) && 
                <div className="notblog">
                        <h3>Not Found !</h3>
                </div>
            }
        </>
    )
}