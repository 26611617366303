import ReactDOM  from "react-dom";
import App from "./App"
import "./index.css"
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Project from "./components/project/Project";
import { Provider } from "react-redux";
import { store } from "./redux/Blog/store";
import Blog from "./components/blog/Blog";


ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App/>} />
                <Route path="/my-projects" element={<Project/>} />
                <Route path="/blog" element={<Blog/>} />
            </Routes>
        </BrowserRouter>
    </Provider>,
document.getElementById("root"))





