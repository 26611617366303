import React from 'react'
import "./about.css"
import me from "../../assets/me.jpg"
import {FaAward} from "react-icons/fa"
import {FaUsers} from "react-icons/fa"
import {VscFolderLibrary} from "react-icons/vsc"

const About = () => {
  return (
    <section id='about' className='hidden'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
            <div className="about__me-image">
              <img src={me} alt="About Me Image" />
            </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about_card">
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>8+ Months  </small>
            </article>
            <article className="about_card">
              <FaUsers className='about__icon'/>
              <h5>Clients</h5>
              <small>2+ Worldwide </small>
            </article>
            <article className="about_card">
              <VscFolderLibrary className='about__icon'/>
              <h5>Projects</h5>
              <small>6+ Completed </small>
            </article>
          </div>
          <p>
            I am Amine Daaboub, a passionate and
            skilled full stack developer based in
            Ouarzazate. With a strong foundation in
            both front-end and back-end technologies,
            I thrive in creating dynamic and userfriendly web applications. I have a
            comprehensive understanding of
            programming languages such as 
            JavaScript,Php,Python as well as
            experience in utilizing frameworks

          </p>
          <a href="#contact" className='btn btn-primary'> Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About


 