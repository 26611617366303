import React from 'react'
import "./tetstimonials.css"
import p1 from "../../assets/1.jpg"
import p2 from "../../assets/2.jpg"
import p3 from "../../assets/3.webp"
import p4 from "../../assets/h.jpg"

// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


const data=[
  {
    avatar:p1,
    Name:"Ali",
    review: `I was truly impressed by your professionalism and expertise in delivering top-notch solutions. Your work has made a significant impact on our business. Highly recommended!`
  },
  {
    avatar:p4,
    Name:"Hind",
    review: `Working with you has been a pleasure. Your innovative ideas and attention to detail have exceeded our expectations. Your contributions have been instrumental in our success`
  },
  {
    avatar:p2,
    Name:"Anas",
    review: `Your skills are remarkable. The projects you've handled for us were executed flawlessly. Your dedication and commitment to quality are truly impressive`
  },
  {
    avatar:p3,
    Name:"Walid",
    review: `I've had the pleasure of collaborating with you on various projects. Your professionalism and ability to deliver under tight deadlines have been exceptional. Looking forward to more successful partnerships`
  },
]

const Tetstimonials = () => {
  return (
    <section id='tetstimonials' className='hidden'>
      <h5>Review from clients</h5>
      <h2>Tetstimonials</h2>
      <Swiper className="container tetstimonials__container"
            // install Swiper modules
            modules={[Pagination]}
            spaceBetween={40}
            slidesPerView={1}
            pagination={{ clickable: true }}>

        {
          data.map(({avatar,Name,review},index)=>{
            return(
            <SwiperSlide className='tetstimonial' key={index}>
            <div className="client__avatar">
              <img src={avatar} alt="" />
            </div>
              <h5>{Name}</h5>
              <small className='client__review'>{review}</small>
          </SwiperSlide>
          )
          })
        }

      </Swiper>
    </section>
  )
}

export default Tetstimonials