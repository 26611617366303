import React, { createRef, useState } from "react";
import "./Project.css";

import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css"

// media file
import pfe from "../../assets/video/fullstack/pfe.mp4"
import gestion_resturant from "../../assets/video/backend/gestion_resturant.mp4"
import stage from "../../assets/video/backend/stage.mp4"
import pfe_poster from "../../assets/video/fullstack/poster/pfe_poster.png"
import stage_poster from "../../assets/video/backend/poster/stage_poster.png"
import poster_gestion_resturant from "../../assets/video/backend/poster/poster_gestion_resturant.png"
import dapos from "../../assets/video/backend/dapos.mp4"
import dapospost from "../../assets/video/backend/poster/poster_DAPOS.jpg"
import dablog from "../../assets/video/fullstack/dablog.mp4"
import dablogpost from "../../assets/video/fullstack/poster/dablog.png"
import worldbyamine from "../../assets/video/frontend/worldbyamine.mp4"
import wpost from "../../assets/video/frontend/wposter.png"
import { Link } from "react-router-dom";

const Project = () => {
    const [ full_stack, setFull_stack ] = useState(1)
    const [ back_end, setBack_end ] = useState(0)
    const [ front_end, setFront_end ] = useState(0)

    const [model, setModel] = useState(false)

    const fullstack_data = [
        {
            id:1,
            video:pfe,
            poster:pfe_poster
        },
        {
            id:2,
            video:dablog,
            poster:dablogpost
        },
    ]
    const backend_data = [
        {
            id:1,
            video:stage,
            poster: stage_poster
        },
        {
            id:2,
            video:gestion_resturant,
            poster: poster_gestion_resturant
        },
        {
            id:3,
            video:dapos,
            poster: dapospost
        }
    ]

    const fronend_data = [ 
        {
            id: 1,
            video: worldbyamine,
            poster: wpost
        }
    ]

    const handleFullStack = (e) => {
        e.preventDefault()
        setFull_stack(1)
        setBack_end(0)
        setFront_end(0)
    }
    const handleBackEnd = (e) => {
        e.preventDefault()
        setFull_stack(0)
        setBack_end(1)
        setFront_end(0)
    }
    const handleFrontEnd = (e) => {
        e.preventDefault()
        setFull_stack(0)
        setBack_end(0)
        setFront_end(1)
    }
    return (
        <>
        <div>
                <Link to={"/"} ><i class="bi bi-arrow-return-left" style={{ fontSize: "20px", marginLeft: "20px" }}></i></Link>
        </div>
            <div className="menu_section">
                <ul>
                    <li>
                        <a href="#" onClick={(e) => handleFullStack(e)} className={full_stack ? "btn active" : "btn"}>FULL STACK</a>
                    </li>
                    <li>
                        <a href="#" onClick={(e) => handleBackEnd(e)} className={back_end ? "btn active" : "btn"}>BACK END</a>
                    </li>
                    <li>
                        <a href="#" onClick={(e) => handleFrontEnd(e)} className={front_end ? "btn active" : "btn"}>FRONT END</a>
                    </li>
                </ul>
            </div>
            <div className="projects-section">
                {full_stack ? 
                    
                        fullstack_data.map(f => {
                            let divRef = createRef(null);
                            const openModel = () => {
                                divRef.current.classList.remove("video")
                                divRef.current.classList.add("model")
                                setModel(true)
                            }
                            const closeModel = () => {
                                divRef.current.classList.add("video")
                                divRef.current.classList.remove("model")
                                setModel(false)
                            }
                            return (
                                <div className="video" ref={divRef}>
                                    {model && <button className="model-close-btn" onClick={() => closeModel()}>X</button>}
                                    <div className="video_container" onClick={() => openModel()} >
                                    <Video
                                        style={{ with: "100%" }}
                                        autoPlay={model}
                                        controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
                                        poster={f.poster}
                                    >
                                        <source src={f.video} type="video/webm" />
                                    </Video>
                                </div>
                                </div>
                            )
                        })
                    
                : ""}
                {back_end ? 
                backend_data.map((vd) => {
                    let divRef = createRef(null);
                    const openModel = () => { 
                        divRef.current.classList.remove("video")
                        divRef.current.classList.add("model")
                        setModel(true)
                    }
                    const closeModel = () => { 
                        divRef.current.classList.add("video")
                        divRef.current.classList.remove("model")
                        setModel(false)
                    }
                    return (
                        <div ref={divRef} className="video">
                            {model && <button className="model-close-btn" onClick={() => closeModel()}>X</button>}
                            <div className="video_container" onClick={() => openModel()}>
                                <Video
                                    style={{ with: "100%" }}
                                    autoPlay={model}
                                    controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
                                    poster={vd.poster}
                                >
                                    <source src={vd.video} type="video/webm" />
                                </Video>
                            </div>
                        </div>
                    )
                })

                :""}
                {front_end ? 
                    fronend_data.map((vd) => {
                        let divRef = createRef(null);
                        const openModel = () => {
                            divRef.current.classList.remove("video")
                            divRef.current.classList.add("model")
                            setModel(true)
                        }
                        const closeModel = () => {
                            divRef.current.classList.add("video")
                            divRef.current.classList.remove("model")
                            setModel(false)
                        }
                        return (
                            <div ref={divRef} className="video">
                                {model && <button className="model-close-btn" onClick={() => closeModel()}>X</button>}
                                <div className="video_container" onClick={() => openModel()}>
                                    <Video
                                        style={{ with: "100%" }}
                                        autoPlay={model}
                                        controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
                                        poster={vd.poster}
                                    >
                                        <source src={vd.video} type="video/webm" />
                                    </Video>
                                </div>
                            </div>
                        )
                    }) :""}
                
                
            </div>
        </>
    )
}

export default Project