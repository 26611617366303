import React from 'react'
import CV from "../../assets/aminedaaboub.pdf"
import { Link } from 'react-router-dom'

const Cta = () => {
  return (
    <div className='cta'>
      <Link to="/my-projects" className='btn btn-primary'>My projects</Link>
        <a href={CV} download className='btn'>Download CV</a>
      <Link to='/blog' className='btn btn-primary'>Blog</Link>
    </div>
  )
}

export default Cta