import React from 'react'
import "./portfolio.css"
import IMG from "../../assets/me.jpg"
import worldbyamine from "../../assets/video/frontend/wposter.png"
import gestionRES from "../../assets/video/backend/poster/poster_gestion_resturant.png"
import dovision from "../../assets/video/fullstack/poster/pfe_poster.png"
import dablog from "../../assets/video/fullstack/poster/dablog.png"
import dapos from "../../assets/video/backend/poster/poster_DAPOS.jpg"
import stage from "../../assets/video/backend/poster/stage_poster.png"
import { Link } from 'react-router-dom'

const data = [
  {
    id:1,
    img: worldbyamine,
    title:"Discovering Countries: A Modern Website Created with ReactJS",
    github:"https://github.com/amine02da/world_by_amine",
    demo:"https://worldbyamine.netlify.app"
    
  },
  {
    id:2,
    img: gestionRES,
    title:"Dafood: Revolutionizing Restaurant Management with Laravel",
    github:"https://github.com/amine02da/gestion_restaurant",
    demo:"/my-projects"
    
  },
  {
    id:3,
    img: dovision,
    title:"Streamlining Optical Testing and Management with Laravel and ReactJS",
    github:"http://github.com/",
    demo:"/my-projects"
    
  },
  {
    id:4,
    img: dablog,
    title:"Crafting Dynamic Blogs with the MERN Stack Experience",
    github:"https://github.com/amine02da/da_blog",
    demo:"/my-projects"
    
  },
  {
    id:5,
    img: dapos,
    title:"Empowering Businesses with Efficient Point of Sale Solutions with laravel",
    github:"https://github.com/amine02da/dapos",
    demo:"/my-projects"
    
  },
  {
    id:6,
    img: stage,
    title:" Empowering Diabetic Health Management with laravel",
    github:"http://github.com/",
    demo:"/my-projects"
    
  },
]
const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container hidden">
          {
            data.map(({id,img,title,github,demo})=>{
              return(
                <article className='portfolio__item' key={id}>
                <div className="protfolio__item-image">
                  <img src={img} alt={title} />
                </div>
                <h3>{title}</h3>
                <div className="portfolio__item-cta">
                  <a href={(id != 3 || id != 6) && github} className='btn' target="_blank">Github</a>
                    <Link to={demo} className='btn btn-primary' target={id == 1 ? "_blank" : ""}>Live Demo </Link>
                </div>
              </article>
              )
            })
          }
      </div>
    </section>
  )
}

export default Portfolio