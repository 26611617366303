import React from 'react'
import "./services.css"
import {BiCheck} from "react-icons/bi"

const Services = () => {
  return (
    <section id='services' className='hidden'>
      <h5>What I Offer</h5>
      <h2>Services</h2>
      <div className="container services__container">
        <article className='service'>
          <div className="service__head">
            <h3>Front end website</h3>
          </div>
          <ul className='Service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Responsive Web Design</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Customized and Creative Design</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Integration of Third-Party APIs</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>User-Friendly and Responsive Interfaces</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>And more services</p>
            </li>
          </ul>
        </article>
        <article className='service'>
          <div className="service__head">
            <h3>backend website</h3>
          </div>
          <ul className='Service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Server-Side Logic Development</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Database Integration</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>RESTful API Implementation</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>User Authentication and Security.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>And more services</p>
            </li>
          </ul>
        </article>
        <article className='service'>
          <div className="service__head">
            <h3>Full stack website</h3>
          </div>
          <ul className='Service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Front-End and Back-End Integration</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Complete User Workflow Implementation</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Database Setup and Management</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>And more services</p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default Services