import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getBlog = createAsyncThunk("blog/get", async () => {
    const res = await axios.get("https://aminedaaboub.com/porapi/blog")
    return res.data
})

export const BlogSlice = createSlice({
    
    name:"blog",

    initialState: {
        blog : [],
        loading : false,
        error : false
    },
    reducers: {

    },

    extraReducers : (builder) => {
        builder.addCase(getBlog.pending, (state) => {
            state.loading = true
        })
        
        builder.addCase(getBlog.fulfilled, (state, action) => {
            state.loading = false
            state.blog = action.payload
        }) 

        builder.addCase(getBlog.rejected, (state) => {
            state.loading = false
            state.error = true
        }) 
    }
})

export default BlogSlice.reducer